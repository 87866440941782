"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EntityBuilder = void 0;
var storage_1 = require("./support/storage");
var isEntityType_1 = require("./support/isEntityType");
var StringHelper_1 = require("./support/StringHelper");
var EntityBuilder = (function () {
    function EntityBuilder() {
    }
    EntityBuilder.buildOne = function (buildClass, sourceData) {
        var entity = new buildClass();
        return entity.fromJson(sourceData);
    };
    EntityBuilder.buildMany = function (buildClass, sourceData) {
        var _this = this;
        return sourceData.map(function (entityData) {
            return _this.buildOne(buildClass, entityData);
        });
    };
    EntityBuilder.fill = function (entity, data) {
        for (var key in data) {
            EntityBuilder.fillProperty(entity, key, data[key]);
        }
        return entity;
    };
    EntityBuilder.fillProperty = function (entity, key, value) {
        if (typeof value === "undefined") {
            return;
        }
        var metadata = storage_1.defaultMetadataStorage.findTypeMetadata(entity.constructor, key);
        if (metadata) {
            EntityBuilder.fillTypeDecoratedProperty(entity, metadata, value);
            return;
        }
        entity.setProp(StringHelper_1.StringHelper.toCamel(key), value);
    };
    EntityBuilder.fillTypeDecoratedProperty = function (entity, metadata, value) {
        if (typeof value === "object" && value !== null && !Array.isArray(value)) {
            if ((0, isEntityType_1.isEntityType)(metadata.type)) {
                entity.setProp(metadata.propertyName, EntityBuilder.buildOne(metadata.type, value));
            }
            else {
                entity.setProp(metadata.propertyName, new metadata.type(value));
            }
            return;
        }
        if (Array.isArray(value) && value.length > 0) {
            if ((0, isEntityType_1.isEntityType)(metadata.type)) {
                entity.setProp(metadata.propertyName, EntityBuilder.buildMany(metadata.type, value));
            }
            else {
                entity.setProp(metadata.propertyName, value.map(function (item) { return new metadata.type(item); }));
            }
            return;
        }
        entity.setProp(metadata.propertyName, value);
    };
    return EntityBuilder;
}());
exports.EntityBuilder = EntityBuilder;
