import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Entity } from '@jobilla/entity';
import { Observable } from 'rxjs';
import { pagedEntities } from '@core/utils/rxjs';
import { Pager } from '@entities/pagers/pager.class';
import { environment } from '@environment';
import merge from 'lodash-es/merge';
import { QueryFilters } from '@entities/shared/query-filters.class';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private apiBaseUrl: string = environment.api.baseUrl;
  private apiVersion: string = environment.api.version;

  public constructor(private http: HttpClient) {}

  public getPagedEntities<T extends Entity>(pagedParams: {
    entityClass: new () => T;
    pager: Pager<T>;
    path: string;
    filters?: QueryFilters;
    options?: any;
  }): Observable<Pager<T>> {
    if (!pagedParams.options) {
      pagedParams.options = { params: {} };
    }
    const pagerParams = pagedParams.pager?.toUrlParamObject();
    const filterParams = pagedParams.filters?.toUrlParamObject();
    pagedParams.options.params = merge(pagedParams.options.params, pagerParams, filterParams);

    return this.get<T>(pagedParams.path, pagedParams.options).pipe(pagedEntities<T>(pagedParams.entityClass));
  }

  public get(path: string, options?: { responseType?: 'text'; observe?: 'body'; params?: any }): Observable<string>;
  public get(
    path: string,
    options?: { responseType?: 'arraybuffer'; observe?: 'body'; params?: any; headers?: any }
  ): Observable<string>;
  public get<T>(path: string, options?: { responseType?: 'json'; observe?: 'body'; params?: any }): Observable<T>;
  public get<T>(path: string, options?: { responseType?: 'blob'; observe?: 'body'; params?: any }): Observable<T>;
  public get<T>(path: string, options?: any): Observable<any> {
    const apiUrl: string = this.getUrl(path);
    return this.http.get<T>(apiUrl, options);
  }

  public put<T>(path: string, body: any): Observable<T> {
    const apiUrl: string = this.getUrl(path);
    return this.http.put<T>(apiUrl, body);
  }

  public post<T>(path: string, body: any, options?: any): Observable<any> {
    const apiUrl: string = this.getUrl(path);
    return this.http.post<T>(apiUrl, body, options);
  }

  public patch<T>(path: string, body: any): Observable<T> {
    const apiUrl: string = this.getUrl(path);
    return this.http.patch<T>(apiUrl, body);
  }

  /**
   * @deprecated This hits `v1` API (no API version in URL), migrate to `v2` if available and use `post`
   */
  public legacyPost<T>(path: string, options?: any): Observable<T> {
    const apiUrl: string = this.getLegacyUrl(path);
    return this.http.post<T>(apiUrl, options);
  }

  public delete<T>(path: string): Observable<T> {
    const apiUrl = this.getUrl(path);
    return this.http.delete<T>(apiUrl);
  }

  public getUrl(path: string): string {
    if (path.startsWith('/')) {
      return this.apiBaseUrl + '/' + this.apiVersion + path;
    } else {
      return this.apiBaseUrl + '/' + this.apiVersion + '/' + path;
    }
  }

  private getLegacyUrl(path: string): string {
    if (path.startsWith('/')) {
      return this.apiBaseUrl + path;
    } else {
      return this.apiBaseUrl + '/' + path;
    }
  }
}
