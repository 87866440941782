"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MetadataStorage = exports.DefaultValueCallbackMetadata = void 0;
var DefaultValueCallbackMetadata = (function () {
    function DefaultValueCallbackMetadata(target, propertyName, callback, condition) {
        this.target = target;
        this.propertyName = propertyName;
        this.callback = callback;
        this.condition = condition;
    }
    return DefaultValueCallbackMetadata;
}());
exports.DefaultValueCallbackMetadata = DefaultValueCallbackMetadata;
var MetadataStorage = (function () {
    function MetadataStorage() {
        this.typeMetadatas = [];
        this.excludedProperties = [];
    }
    MetadataStorage.prototype.addTypeMetadata = function (metadata) {
        this.typeMetadatas.push(metadata);
    };
    MetadataStorage.prototype.addExcludeProperty = function (excludeMeta) {
        this.excludedProperties.push(excludeMeta);
    };
    MetadataStorage.prototype.findTypeMetadata = function (target, propertyName) {
        var metadataFromTarget = this.typeMetadatas.find(function (meta) {
            return meta.target === target && meta.sourcePropertyName === propertyName;
        });
        var metadataForAliasedProperty = this.typeMetadatas.find(function (meta) { return meta.target === target && meta.propertyName === propertyName; });
        var metadataFromChildren = this.typeMetadatas.find(function (meta) {
            return target.prototype instanceof meta.target &&
                meta.sourcePropertyName === propertyName;
        });
        return (metadataFromTarget || metadataForAliasedProperty || metadataFromChildren);
    };
    MetadataStorage.prototype.isPropertyExcluded = function (target, propertyName) {
        return (this.excludedProperties.some(function (propertyMeta) {
            return propertyMeta.target === target &&
                propertyMeta.propertyName === propertyName;
        }) ||
            this.excludedProperties.some(function (propertyMeta) {
                return target.prototype instanceof propertyMeta.target &&
                    propertyMeta.propertyName === propertyName;
            }));
    };
    return MetadataStorage;
}());
exports.MetadataStorage = MetadataStorage;
