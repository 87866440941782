"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Entity = void 0;
var storage_1 = require("./support/storage");
var EntityBuilder_1 = require("./EntityBuilder");
var toJson_1 = require("./support/toJson");
var Entity = (function () {
    function Entity() {
    }
    Entity.prototype.hasProp = function (key) {
        if (Object.prototype.hasOwnProperty.call(this, key)) {
            return true;
        }
        return !!storage_1.defaultMetadataStorage.findTypeMetadata(this.constructor, key);
    };
    Entity.prototype.getProp = function (key) {
        if (!this.hasProp(key)) {
            return;
        }
        return this[key];
    };
    Entity.prototype.setProp = function (key, value) {
        if (!this.hasProp(key)) {
            return;
        }
        this[key] = value;
    };
    Entity.prototype.toJson = function (toSnake, asString) {
        if (toSnake === void 0) { toSnake = true; }
        if (asString === void 0) { asString = false; }
        return toJson_1.toJson.call(this, toSnake, asString);
    };
    Entity.prototype.fromJson = function (data) {
        EntityBuilder_1.EntityBuilder.fill(this, data);
        return this;
    };
    return Entity;
}());
exports.Entity = Entity;
