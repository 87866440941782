"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.toJson = void 0;
var storage_1 = require("./storage");
var StringHelper_1 = require("./StringHelper");
var Entity_1 = require("../Entity");
function toJson(toSnake, asString) {
    if (toSnake === void 0) { toSnake = true; }
    if (asString === void 0) { asString = false; }
    var data = {};
    for (var key in this) {
        if (!this.hasOwnProperty(key)) {
            continue;
        }
        if (storage_1.defaultMetadataStorage.isPropertyExcluded(this.constructor, key)) {
            continue;
        }
        var outputKey = toSnake ? StringHelper_1.StringHelper.toSnake(key) : key;
        var value = this[key];
        if (value instanceof Entity_1.Entity) {
            data[outputKey] = value.toJson(toSnake, asString);
            continue;
        }
        var metadata = storage_1.defaultMetadataStorage.findTypeMetadata(this.constructor, key);
        if (Array.isArray(value) &&
            value.length > 0 &&
            value[0] instanceof Object) {
            if (value[0] instanceof Entity_1.Entity) {
                data[outputKey] = value.map(function (entity) {
                    return entity.toJson(toSnake, asString);
                });
            }
            if (metadata && metadata.type === Object) {
                data[outputKey] = value;
            }
            continue;
        }
        if (value !== null && typeof value === "object" && !Array.isArray(value)) {
            if (metadata && metadata.type === Object) {
                data[outputKey] = value;
            }
            continue;
        }
        data[outputKey] = value;
    }
    return asString ? JSON.stringify(data) : data;
}
exports.toJson = toJson;
