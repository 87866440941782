"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TypeMetadata = void 0;
function isResolverFunction(type) {
    var _a;
    return ((_a = type.name) === null || _a === void 0 ? void 0 : _a.length) === 0;
}
var TypeMetadata = (function () {
    function TypeMetadata(target, propertyName, sourcePropertyName, _type) {
        this.target = target;
        this.propertyName = propertyName;
        this.sourcePropertyName = sourcePropertyName;
        this._type = _type;
    }
    Object.defineProperty(TypeMetadata.prototype, "type", {
        get: function () {
            if (isResolverFunction(this._type)) {
                var resolvedType = this._type();
                return TypeMetadata.unpackType(resolvedType);
            }
            return this._type;
        },
        enumerable: false,
        configurable: true
    });
    TypeMetadata.unpackType = function (type) {
        if (typeof type === "object") {
            return type.default;
        }
        return type;
    };
    return TypeMetadata;
}());
exports.TypeMetadata = TypeMetadata;
