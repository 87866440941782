import { SortDirection } from '@angular/material/sort';

export interface IPaginationParams {
  page: number;
  limit: number;
  sortBy: string;
  sortOrder: SortDirection;
}

export class Pager<T> {
  public limit: number = 10;
  public total: number;
  public totalPages: number;
  public page: number = 1;
  public data: T[] = [];
  public sortBy: string = null;
  public sortOrder: SortDirection = null;
  private _defaultPage: number = 1;

  public constructor(initData?: Partial<Pager<T>>) {
    Object.assign(this, initData);
  }

  public hasNextPage(): boolean {
    return this.page < this.totalPages;
  }

  public nextPage(): void {
    if (this.hasNextPage()) {
      this.page += 1;
    }
  }

  public hasPreviousPage(): boolean {
    return this.page > 1;
  }

  public previousPage(): void {
    if (this.hasPreviousPage()) {
      this.page -= 1;
    }
  }

  public hasPage(pageNumber: number): boolean {
    return this.totalPages >= pageNumber && pageNumber > 0;
  }

  public setSort(sortBy: string, sortOrder: SortDirection): void {
    this.sortBy = sortBy;
    this.sortOrder = sortOrder;
  }

  public reset() {
    this.page = this._defaultPage;
    this.data = [];
  }

  public toUrlParamObject(): IPaginationParams {
    const params: any = {};
    if (this.page) {
      params.page = this.page;
    }

    if (this.sortBy) {
      params.sortBy = this.sortBy;
    }

    if (this.sortOrder) {
      params.sortOrder = this.sortOrder;
    }

    if (this.limit) {
      params.limit = this.limit;
    }

    return params;
  }
}
