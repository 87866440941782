import { Entity, EntityBuilder } from '@jobilla/entity';
import { Pager } from './pager.class';

export class PagerBuilder {
  public static buildFromHttpResponse<T extends Entity>(buildClass: new () => T, pagedHttpResponse: any): Pager<T> {
    const pager = new Pager<T>();
    pager.limit = pagedHttpResponse.limit;
    pager.page = pagedHttpResponse.page;
    pager.total = pagedHttpResponse.total;
    pager.totalPages = pagedHttpResponse.totalPages;
    pager.sortBy = pagedHttpResponse.sortBy;
    pager.sortOrder = pagedHttpResponse.sortOrder.toLowerCase();

    pager.data = EntityBuilder.buildMany<T>(buildClass, pagedHttpResponse.data);

    return pager;
  }

  public static buildFromUrlParams<T extends Entity>(urlParams: any, previousPager?: Pager<T>): Pager<T> {
    const pager = previousPager ?? new Pager<T>();
    pager.page = +(urlParams.page ?? pager.page);
    pager.data = [];
    pager.sortBy = urlParams.sortBy;
    pager.sortOrder = urlParams.sortOrder;
    return pager;
  }
}
