export const environment = {
  production: false,
  environment: 'staging',
  disableAnimations: false,
  sentry: {
    enabled: true,
    dsn: 'https://c2a33ac6589f4c2598c441d82eacc906@o475309.ingest.sentry.io/4504204294029312',
    tracePropagationTargets: ['preprod.paidiem.com'],
    tracesSampleRate: 1.0,
    showCrashReport: false,
  },
  api: { baseUrl: 'https://api.preprod.paidiem.com', version: 'v2' },
  analytics: {
    enabled: true,
    segmentWriteKey: 'FlfNq9MCHi4kVMySammze5xpBrcVQKXS',
  },
  thirdParties: {
    unified: {
      env: 'Sandbox',
      api: 'https://api.unified.to',
      workspaceId: '6490b95addeea9ae176ddf37',
    },
  },
};
